<script lang="tsx">
import type { PropType, SlotsType, VNode } from 'vue'
import type { BaseUiInputProps } from '../BaseUiInput.vue'
import type { BaseUiFormGroupProps, BaseUiFormGroupSlots } from '../form/BaseUiFormGroup.vue'
import type { BaseFormElementProps, BaseVariants, SizeProp } from '@core-types/components'
import { BaseUiInput, BaseUiFormGroup } from '#components'
import type { FormFieldObject } from '@core-types/form'

export type BaseUiFormInputProps<Colors extends string, Variants extends string, Sizes extends string> = {
    inputClass?: string
} & BaseUiInputProps<Colors, Variants, Sizes> & BaseUiFormGroupProps & Omit<
    BaseFormElementProps<string | number>, 'descriptionId'
>

type BaseUiFormInputSlots<Colors extends string, Variants extends string, Sizes extends string> = Omit<BaseUiFormGroupSlots, 'default'> & {
    default: {}
}

type ComponentOptions = {}

export function defineComponentBaseUiFormInput<
    Colors extends string,
    Variants extends string = BaseVariants,
    Sizes extends string = SizeProp,
>(options?: ComponentOverrideOptions<ComponentOptions, BaseUiFormInputProps<Colors, Variants, Sizes>, BaseUiFormInputSlots<Colors, Variants, Sizes>>) {
    return defineComponent(
        (props: BaseUiFormInputProps<Colors, Variants, Sizes>, ctx) => {

            const parentScope = getScopeIdAttr()

            return () => (
                <BaseUiFormGroup
                    form={props.form}
                    help={props.help}
                    descriptionAbove={props.descriptionAbove}
                    descriptionBelow={props.descriptionBelow}
                    hideRequired={props.hideRequired}
                    label={props.label}
                    noLabelElement={props.noLabelElement}
                    errorId={props.errorId}
                    descriptionId={props.descriptionId}
                    disabled={props.disabled}
                    loading={props.loading}
                    required={props.required}
                    id={props.id}
                    ariaLabel={props.ariaLabel}
                    ariaInvalid={props.ariaInvalid}
                >
                    {(slotData: BaseUiFormGroupSlots['default']) => [
                        slotData.renderLabel(
                            (
                                renderSlot(ctx.slots.default, options?.slots?.default, {}, (
                                    <>
                                        {props.label}
                                    </>
                                ))
                            ) as VNode,
                            {
                                hasDefaultSlotContent: ctx.slots.default !== undefined || !!options?.slots?.default,
                            }
                        ),

                        slotData.renderAboveDescription(),

                        (
                            <BaseUiInput
                                modelValue={props.modelValue}
                                onUpdate:modelValue={(val: string | number) => ctx.emit('update:modelValue', val)}
                                modelModifiers={props.modelModifiers}
                                form={props.form}
                                onUpdate:form={(val: FormFieldObject<string | number>) => ctx.emit('update:form', val)}
                                formModifiers={props.formModifiers}

                                id={slotData.inputId}
                                // TODO: remove later
                                class={props.inputClass}

                                // @ts-ignore
                                color={props.color}
                                // @ts-ignore
                                variant={props.variant}
                                // @ts-ignore
                                size={props.size}

                                type={props.type}
                                autocomplete={props.autocomplete}
                                placeholder={props.placeholder}
                                disabled={props.disabled}
                                loading={props.loading}
                                required={slotData.isInputRequired}
                                min={props.min}
                                max={props.max}
                                step={props.step}
                                autofocus={props.autofocus}
                                clearable={props.clearable}
                                showPasswordToggle={props.showPasswordToggle}
                                autoresize={props.autoresize}
                                rows={props.rows}
                                paddingBehavior={props.paddingBehavior}
                                square={props.square}
                                actions={props.actions}

                                descriptionId={slotData.inputDescriptionIds}
                                ariaInvalid={slotData.isInputAriaInvalid}

                                {...parentScope}
                            />
                        ),

                        slotData.renderBelowDescriptionAndError(),

                    ]}
                </BaseUiFormGroup>
            )
        },
        {
            props: {
                modelValue: {
                    type: [String, Number] as PropType<BaseUiFormInputProps<Colors, Variants, Sizes>['modelValue']>,
                    default: options?.props?.modelValue?.default,
                    required: options?.props?.modelValue?.required ?? false,
                },
                modelModifiers: {
                    type: Object as PropType<BaseUiFormInputProps<Colors, Variants, Sizes>['modelModifiers']>,
                    default: options?.props?.modelModifiers?.default,
                    required: options?.props?.modelModifiers?.required ?? false,
                },
                form: {
                    type: Object as PropType<BaseUiFormInputProps<Colors, Variants, Sizes>['form']>,
                    default: options?.props?.form?.default,
                    required: options?.props?.form?.required ?? false,
                },
                formModifiers: {
                    type: Object as PropType<BaseUiFormInputProps<Colors, Variants, Sizes>['formModifiers']>,
                    default: options?.props?.formModifiers?.default,
                    required: options?.props?.formModifiers?.required ?? false,
                },

                help: {
                    type: String as PropType<BaseUiFormInputProps<Colors, Variants, Sizes>['help']>,
                    default: options?.props?.help?.default,
                    required: options?.props?.help?.required ?? false,
                },
                descriptionAbove: {
                    type: String as PropType<BaseUiFormInputProps<Colors, Variants, Sizes>['descriptionAbove']>,
                    default: options?.props?.descriptionAbove?.default,
                    required: options?.props?.descriptionAbove?.required ?? false,
                },
                descriptionBelow: {
                    type: String as PropType<BaseUiFormInputProps<Colors, Variants, Sizes>['descriptionBelow']>,
                    default: options?.props?.descriptionBelow?.default,
                    required: options?.props?.descriptionBelow?.required ?? false,
                },

                hideRequired: {
                    type: Boolean as PropType<BaseUiFormInputProps<Colors, Variants, Sizes>['hideRequired']>,
                    default: options?.props?.hideRequired?.default,
                    required: options?.props?.hideRequired?.required ?? false,
                },
                label: {
                    type: String as PropType<BaseUiFormInputProps<Colors, Variants, Sizes>['label']>,
                    default: options?.props?.label?.default,
                    required: options?.props?.label?.required ?? false,
                },
                noLabelElement: {
                    type: Boolean as PropType<BaseUiFormInputProps<Colors, Variants, Sizes>['noLabelElement']>,
                    default: options?.props?.noLabelElement?.default,
                    required: options?.props?.noLabelElement?.required ?? false,
                },
                errorId: {
                    type: String as PropType<BaseUiFormInputProps<Colors, Variants, Sizes>['errorId']>,
                    default: options?.props?.errorId?.default,
                    required: options?.props?.errorId?.required ?? false,
                },

                // INPUT PROPS
                color: {
                    // @ts-ignore
                    type: String as PropType<BaseUiFormInputProps<Colors, Variants, Sizes>['color']>,
                    default: options?.props?.color?.default,
                    required: options?.props?.color?.required ?? false,
                },
                variant: {
                    // @ts-ignore
                    type: String as PropType<BaseUiFormInputProps<Colors, Variants, Sizes>['variant']>,
                    default: options?.props?.variant?.default,
                    required: options?.props?.variant?.required ?? false,
                },
                size: {
                    // @ts-ignore
                    type: String as PropType<BaseUiFormInputProps<Colors, Variants, Sizes>['size']>,
                    default: options?.props?.size?.default,
                    required: options?.props?.size?.required ?? false,
                },
                type: {
                    type: String as PropType<BaseUiFormInputProps<Colors, Variants, Sizes>['type']>,
                    default: options?.props?.type?.default,
                    required: options?.props?.type?.required ?? false,
                },
                autocomplete: {
                    type: String as PropType<BaseUiFormInputProps<Colors, Variants, Sizes>['autocomplete']>,
                    default: options?.props?.autocomplete?.default,
                    required: options?.props?.autocomplete?.required ?? false,
                },
                placeholder: {
                    type: String as PropType<BaseUiFormInputProps<Colors, Variants, Sizes>['placeholder']>,
                    default: options?.props?.placeholder?.default,
                    required: options?.props?.placeholder?.required ?? false,
                },
                disabled: {
                    type: Boolean as PropType<BaseUiFormInputProps<Colors, Variants, Sizes>['disabled']>,
                    default: options?.props?.disabled?.default,
                    required: options?.props?.disabled?.required ?? false,
                },
                loading: {
                    type: Boolean as PropType<BaseUiFormInputProps<Colors, Variants, Sizes>['loading']>,
                    default: options?.props?.loading?.default,
                    required: options?.props?.loading?.required ?? false,
                },
                required: {
                    type: Boolean as PropType<BaseUiFormInputProps<Colors, Variants, Sizes>['required']>,
                    default: options?.props?.required?.default,
                    required: options?.props?.required?.required ?? false,
                },
                min: {
                    type: [String, Number] as PropType<BaseUiFormInputProps<Colors, Variants, Sizes>['min']>,
                    default: options?.props?.min?.default,
                    required: options?.props?.min?.required ?? false,
                },
                max: {
                    type: [String, Number] as PropType<BaseUiFormInputProps<Colors, Variants, Sizes>['max']>,
                    default: options?.props?.max?.default,
                    required: options?.props?.max?.required ?? false,
                },
                step: {
                    type: [String, Number] as PropType<BaseUiFormInputProps<Colors, Variants, Sizes>['step']>,
                    default: options?.props?.step?.default,
                    required: options?.props?.step?.required ?? false,
                },
                autofocus: {
                    type: Boolean as PropType<BaseUiFormInputProps<Colors, Variants, Sizes>['autofocus']>,
                    default: options?.props?.autofocus?.default,
                    required: options?.props?.autofocus?.required ?? false,
                },
                clearable: {
                    type: Boolean as PropType<BaseUiFormInputProps<Colors, Variants, Sizes>['clearable']>,
                    default: options?.props?.clearable?.default,
                    required: options?.props?.clearable?.required ?? false,
                },
                showPasswordToggle: {
                    type: Boolean as PropType<BaseUiFormInputProps<Colors, Variants, Sizes>['showPasswordToggle']>,
                    default: options?.props?.showPasswordToggle?.default,
                    required: options?.props?.showPasswordToggle?.required ?? false,
                },
                autoresize: {
                    type: Boolean as PropType<BaseUiFormInputProps<Colors, Variants, Sizes>['autoresize']>,
                    default: options?.props?.autoresize?.default,
                    required: options?.props?.autoresize?.required ?? false,
                },
                rows: {
                    type: Number as PropType<BaseUiFormInputProps<Colors, Variants, Sizes>['rows']>,
                    default: options?.props?.rows?.default,
                    required: options?.props?.rows?.required ?? false,
                },
                paddingBehavior: {
                    type: String as PropType<BaseUiFormInputProps<Colors, Variants, Sizes>['paddingBehavior']>,
                    default: options?.props?.paddingBehavior?.default,
                    required: options?.props?.paddingBehavior?.required ?? false,
                },
                square: {
                    type: Boolean as PropType<BaseUiFormInputProps<Colors, Variants, Sizes>['square']>,
                    default: options?.props?.square?.default,
                    required: options?.props?.square?.required ?? false,
                },
                actions: {
                    type: String as PropType<BaseUiFormInputProps<Colors, Variants, Sizes>['actions']>,
                    default: options?.props?.actions?.default,
                    required: options?.props?.actions?.required ?? false,
                },

                descriptionId: {
                    type: [String, Array] as PropType<BaseUiFormInputProps<Colors, Variants, Sizes>['descriptionId']>,
                    default: options?.props?.descriptionId?.default,
                    required: options?.props?.descriptionId?.required ?? false,
                },
                ariaLabel: {
                    type: String as PropType<BaseUiFormInputProps<Colors, Variants, Sizes>['ariaLabel']>,
                    default: options?.props?.ariaLabel?.default,
                    required: options?.props?.ariaLabel?.required ?? false,
                },
                ariaInvalid: {
                    type: Boolean as PropType<BaseUiFormInputProps<Colors, Variants, Sizes>['ariaInvalid']>,
                    default: options?.props?.ariaInvalid?.default,
                    required: options?.props?.ariaInvalid?.required ?? false,
                },
                inputClass: {
                    type: String as PropType<BaseUiFormInputProps<Colors, Variants, Sizes>['inputClass']>,
                    default: options?.props?.inputClass?.default,
                    required: options?.props?.inputClass?.required ?? false,
                },
            },
            slots: Object as SlotsType<BaseUiFormInputSlots<Colors, Variants, Sizes>>,
            emits: {
                'update:modelValue': (val: string | number) => true,
                'update:form': (val: FormFieldObject<string | number>) => true,
            },
        }
    )
}

export default defineComponentBaseUiFormInput()

</script>

<style lang="scss" scoped>
@use "@core-scss/components/BaseUiFormInput.scss" as *;

</style>
