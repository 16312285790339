<script lang="tsx">
import { defineComponentBaseUiFormInput } from '@core/app/components/base/ui/form/BaseUiFormInput.vue'
import type { BaseUiInputColors, BaseUiInputSizes, BaseUiInputVariants } from './BaseUiInput.vue'

export default defineComponentBaseUiFormInput<BaseUiInputColors, BaseUiInputVariants, BaseUiInputSizes>()

</script>

<style lang="scss" scoped>
@use "@core-scss/components/BaseUiFormInput" as *;

</style>
